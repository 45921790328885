<template>
  <div class="ElementsContractuels" >
    <validationCartouche
      :hasCedantValidated="elementsContractuelsActiviteCedee.has_cedant_validated"
      :hasRepreneurValidated="elementsContractuelsActiviteCedee.has_repreneur_validated"
    />
    <div class="container-fluid">
      <h2 class="gavc-h2">Éléments contractuels de l’activité cédée </h2>

      <div class="mt-4 mb-4 d-flex justify-content-between">
        <button
          class="gavc-btn gavc-btn--bone"
          @click="$emit('previous-step')"
        >
          <i class="gavc-icon gavc-icon-arrow-left"></i> Étape précédente
        </button>
        <button
          v-if="elementsContractuelsActiviteCedee.has_repreneur_validated && elementsContractuelsActiviteCedee.has_cedant_validated"
          class="gavc-btn"
          @click="$emit('next-step')"
        >
          <i class="gavc-icon gavc-icon-arrow-right"></i> Étape validée - Accéder à l'étape suivante
        </button>
      </div>


      <div v-if="userGroupContains(['REPRENEUR'])">
        <div class="gavc-field">
          <label class="gavc-label" for="elementsContractuelsActiviteCedee-repreneur_comment-input">
            <strong class="gavc-text-tory">En tant que repreneur</strong>, vous pouvez laisser un commentaire au cédant via le champ ci-dessous
          </label>
          <textarea
            class="gavc-textarea"
            id="elementsContractuelsActiviteCedee-repreneur_comment-input"
            maxlength="1000"
            rows="5"
            @change="modifyElementsContractuels"
            placeholder="Je laisse mon commentaire au cédant"
            type="text"
            v-model="elementsContractuelsActiviteCedee.repreneur_comment">
          </textarea>
        </div>
      </div>

      <div class="gavc-bg-bizarre p-4" style="border-radius: 40px;" v-else-if="elementsContractuelsActiviteCedee.repreneur_comment">
        <strong> Le repreneur vous a laissé un commentaire </strong> <br>
        <span style="white-space: pre-line"> {{elementsContractuelsActiviteCedee.repreneur_comment}} </span>
      </div>

      <auditExplanation
        v-if="userGroupContains(['CEDANT'])"
        :isCedant="true"
        :isRepreneur="false"
      />

      <p class="gavc-chapo mt-4" v-else>
        <strong class="gavc-text-tory">En tant que repreneur</strong>,
        <u>vous n'avez aucun document ni information à compléter</u>, il vous est juste nécessaire de valider les documents chargés par le cédant
        puis de valider cette phase.
      </p>

      <b-button class="mt-3 subsection-button" block href="#" v-b-toggle.etablissements-collapse>
        Votre ou vos établissements
        <span class="when-opened float-right">
          <v-icon name="chevron-down" />
        </span>
        <span class="when-closed float-right">
          <v-icon name="chevron-right" />
        </span>
      </b-button>
      <b-collapse id="etablissements-collapse" role="tabpanel">
        <p class="gavc-p">
          Veuillez renseigner les informations concernant votre ou vos établissements (siret et adresse), puis charger le bail de location ou le titre de propriété
        </p>
        <div
          class="w-50 mt-2 p-2 gavc-bg-bizarre"
          style="border-radius: 10px;"
          v-for="etablissement in elementsContractuelsActiviteCedee.etablissements" :key="etablissement.id"
        >
          Siret : {{etablissement.siret.slice(0, 9).match(/.{1,3}/g).join(' ')}}
                  {{etablissement.siret.slice(9, 14)}}
          / Adresse : {{etablissement.address}}
          <button
            v-if="cedantCanModified"
            class="ml-4 float-right"
            style="background-color: transparent !important; border: 0 !important;"
            @click="deleteAuditSectionInformation(etablissement.id, 'etablissement')">
            <i class="gavc-icon gavc-icon-cross"></i>
          </button>
        </div>
        <form v-if="cedantCanModified" class="form-inline mt-4" v-on:submit.prevent="addEtablissement()">
          <input
            class="gavc-input"
            type="text"
            id="add-etablissement-first-name"
            placeholder="Siret"
            maxlength="14"
            name="add-etablissement-first-name"
            v-model="newEtablissementSiret"
          >
          <input
            class="gavc-input"
            type="text"
            id="add-etablissement-address"
            placeholder="Adresse"
            name="add-etablissement-address"
            v-model="newEtablissementAddress"
          >

          <button
            class="gavc-btn"
            :disabled="$v.newEtablissementSiret.$invalid || $v.newEtablissementAddress.$invalid"
            type="submit"
          >
            Ajouter un établissement
          </button>
        </form>

        <p class="gavc-p">
          Documents à charger : <strong>Bail de location ou titre de propriété des établissements ci-dessus</strong>
        </p>

        <auditItem
          :disabled="!cedantCanModified"
          @document-deleted="deleteAuditItem($event, 'elementsContractuelsActiviteCedee')"
          @document-download="downloadAuditDocument($event, 'elementsContractuelsActiviteCedee')"
          @document-uploaded="createAuditDocument($event, 'BAIL', elementsContractuelsActiviteCedee.id, 'elementsContractuelsActiviteCedee')"
          @item-validated="validateAuditItem($event, 'elementsContractuelsActiviteCedee', true)"
          @item-unvalidated="validateAuditItem($event, 'elementsContractuelsActiviteCedee', false)"
          :items="filterDocuments('BAIL', 'CEDANT')"
          @justificatif-added="createAuditItemWithoutDocument($event, 'BAIL', elementsContractuelsActiviteCedee.id, 'elementsContractuelsActiviteCedee')"
          type="BAIL"
        />
      </b-collapse>


      <b-button class="mt-3 subsection-button" block href="#" v-b-toggle.fournisseurs-collapse>
        Fournisseurs
        <span class="when-opened float-right">
          <v-icon name="chevron-down" />
        </span>
        <span class="when-closed float-right">
          <v-icon name="chevron-right" />
        </span>
      </b-button>
      <b-collapse id="fournisseurs-collapse" role="tabpanel">
        <p class="gavc-p">
          Documents à charger : <strong>Contrats fournisseurs principaux</strong>. <br>
          Il est nécessaire de vérifier si le contrat prévoit une clause de contrôle entraînant la résiliation du contrat en cas de cession
        </p>
        <auditItem
          :disabled="!cedantCanModified"
          @document-deleted="deleteAuditItem($event, 'elementsContractuelsActiviteCedee')"
          @document-download="downloadAuditDocument($event, 'elementsContractuelsActiviteCedee')"
          @document-uploaded="createAuditDocument($event, 'CONTRATS_FOURNISSEURS', elementsContractuelsActiviteCedee.id, 'elementsContractuelsActiviteCedee')"
          @item-validated="validateAuditItem($event, 'elementsContractuelsActiviteCedee', true)"
          @item-unvalidated="validateAuditItem($event, 'elementsContractuelsActiviteCedee', false)"
          :items="filterDocuments('CONTRATS_FOURNISSEURS', 'CEDANT')"
          @justificatif-added="createAuditItemWithoutDocument($event, 'CONTRATS_FOURNISSEURS', elementsContractuelsActiviteCedee.id, 'elementsContractuelsActiviteCedee')"
          type="CONTRATS_FOURNISSEURS"
        />
      </b-collapse>

      <b-button class="mt-3 subsection-button" block href="#" v-b-toggle.clients-collapse>
        Clients
        <span class="when-opened float-right">
          <v-icon name="chevron-down" />
        </span>
        <span class="when-closed float-right">
          <v-icon name="chevron-right" />
        </span>
      </b-button>
      <b-collapse id="clients-collapse" role="tabpanel">
        <p class="gavc-p">
          Documents à charger : <strong>Extraction du portefeuille client pour continuation de l’activité</strong> (Extraction CRM, contrats clients cadre si B2B, etc.)
        </p>
        <auditItem
          :disabled="!cedantCanModified"
          @document-deleted="deleteAuditItem($event, 'elementsContractuelsActiviteCedee')"
          @document-download="downloadAuditDocument($event, 'elementsContractuelsActiviteCedee')"
          @document-uploaded="createAuditDocument($event, 'PORTEFEUILLE_CLIENT', elementsContractuelsActiviteCedee.id, 'elementsContractuelsActiviteCedee')"
          @item-validated="validateAuditItem($event, 'elementsContractuelsActiviteCedee', true)"
          @item-unvalidated="validateAuditItem($event, 'elementsContractuelsActiviteCedee', false)"
          :items="filterDocuments('PORTEFEUILLE_CLIENT', 'CEDANT')"
          @justificatif-added="createAuditItemWithoutDocument($event, 'PORTEFEUILLE_CLIENT', elementsContractuelsActiviteCedee.id, 'elementsContractuelsActiviteCedee')"
          type="PORTEFEUILLE_CLIENT"
        />
      </b-collapse>

      <b-button class="mt-3 subsection-button" block href="#" v-b-toggle.agrements-collapse>
        Agréments
        <span class="when-opened float-right">
          <v-icon name="chevron-down" />
        </span>
        <span class="when-closed float-right">
          <v-icon name="chevron-right" />
        </span>
      </b-button>
      <b-collapse id="agrements-collapse" role="tabpanel">
        <p class="gavc-p">
          Documents à charger : <strong>Agréments administratifs</strong> (Formation, Orias, etc.)
        </p>
        <auditItem
          :disabled="!cedantCanModified"
          @document-deleted="deleteAuditItem($event, 'elementsContractuelsActiviteCedee')"
          @document-download="downloadAuditDocument($event, 'elementsContractuelsActiviteCedee')"
          @document-uploaded="createAuditDocument($event, 'AGREMENTS_ADMINISTRATIFS', elementsContractuelsActiviteCedee.id, 'elementsContractuelsActiviteCedee')"
          @item-validated="validateAuditItem($event, 'elementsContractuelsActiviteCedee', true)"
          @item-unvalidated="validateAuditItem($event, 'elementsContractuelsActiviteCedee', false)"
          :items="filterDocuments('AGREMENTS_ADMINISTRATIFS', 'CEDANT')"
          @justificatif-added="createAuditItemWithoutDocument($event, 'AGREMENTS_ADMINISTRATIFS', elementsContractuelsActiviteCedee.id, 'elementsContractuelsActiviteCedee')"
          type="AGREMENTS_ADMINISTRATIFS"
        />
      </b-collapse>

      <b-button class="mt-3 subsection-button" block href="#" v-b-toggle.assurance-collapse>
        Assurances
        <span class="when-opened float-right">
          <v-icon name="chevron-down" />
        </span>
        <span class="when-closed float-right">
          <v-icon name="chevron-right" />
        </span>
      </b-button>
      <b-collapse id="assurance-collapse" role="tabpanel">
        <p class="gavc-p">
          Documents à charger : <strong>Assurances et déclarations de sinistres</strong> (RC Pro, assurance automobile, locaux, etc. )
          <span
            v-b-tooltip.hover title="Pour consulter vos contrats d'assurance, rendez-vous sur le site de votre assureur ou contactez une agence"
          >
            <v-icon
              class="ml-2 gavc-text-tory"
              name="info-circle"
            />
          </span>
        </p>
        <auditItem
          :disabled="!cedantCanModified"
          @document-deleted="deleteAuditItem($event, 'elementsContractuelsActiviteCedee')"
          @document-download="downloadAuditDocument($event, 'elementsContractuelsActiviteCedee')"
          @document-uploaded="createAuditDocument($event, 'ASSURANCE', elementsContractuelsActiviteCedee.id, 'elementsContractuelsActiviteCedee')"
          @item-validated="validateAuditItem($event, 'elementsContractuelsActiviteCedee', true)"
          @item-unvalidated="validateAuditItem($event, 'elementsContractuelsActiviteCedee', false)"
          :items="filterDocuments('ASSURANCE', 'CEDANT')"
          @justificatif-added="createAuditItemWithoutDocument($event, 'ASSURANCE', elementsContractuelsActiviteCedee.id, 'elementsContractuelsActiviteCedee')"
          type="ASSURANCE"
        />
      </b-collapse>

      <!-- ######## CEDANT BUTTONS ########## -->
      <div v-if="!elementsContractuelsActiviteCedee.has_cedant_validated && userGroupContains(['CEDANT'])" class="row mt-4">
        <div class="col-12 text-right">
          <validationSectionButton
            @modify="modifyElementsContractuels"
            @validate="validateElementsContractuels(true)"
          />
        </div>
      </div>
      <div v-else-if="elementsContractuelsActiviteCedee.has_cedant_validated && userGroupContains(['CEDANT'])" class="row mt-4">
        <div class="col-12 text-right">
          <button
            class="gavc-btn gavc-btn--bone"
            @click="validateElementsContractuels(false)"
          >
              Invalider cette étape
          </button>
        </div>
      </div>
    </div>


    <div v-if="!elementsContractuelsActiviteCedee.has_repreneur_validated && userGroupContains(['REPRENEUR'])" class="row mt-4">
      <div class="col-12 text-right">
        <validationSectionButton
          @validate="validateElementsContractuels(true)"
          :withModifyButton="false"
        />
      </div>
    </div>
    <div v-else-if="elementsContractuelsActiviteCedee.has_repreneur_validated && userGroupContains(['REPRENEUR'])" class="row mt-4">
      <div class="col-12 text-right">
        <button
          class="gavc-btn gavc-btn--bone"
          @click="validateElementsContractuels(false)"
        >
            Invalider cette étape
        </button>
      </div>
    </div>

    <div class="row mt-4">
      <div class="col-12 col-md-6 pl-4 pr-4">
        <hasValidatedMessage
          v-if="elementsContractuelsActiviteCedee.has_cedant_validated"
          text="Le cédant a validé toute la section"
        />
      </div>
      <div class="col-12 col-md-6 pl-4 pr-4">
        <hasValidatedMessage
          v-if="elementsContractuelsActiviteCedee.has_repreneur_validated"
          text="Le repreneur a validé toute la section"
        />
      </div>
    </div>

    <div class="mt-4 text-center" v-if="elementsContractuelsActiviteCedee.has_repreneur_validated && elementsContractuelsActiviteCedee.has_cedant_validated">
      <button
        class="gavc-btn"
        @click="$emit('next-step')">
            Étape validée - Accéder à l'étape suivante
      </button>
    </div>
  </div>
</template>

<script>
import { validationMixin } from 'vuelidate'
import { integer, minLength, maxLength, required } from 'vuelidate/lib/validators'

import { APIConnected } from '@/api/APIConnected'
import auditItemsMixins from '@/mixins/auditItemsMixins'
import auditSectionInformationMixins from '@/mixins/auditSectionInformationMixins'
import userMixins from '@/mixins/userMixins'
import uxMixins from '@/mixins/uxMixins'

const apiConnectedService = new APIConnected()
const auditItem = () => import('@/components/audit/auditItem')
const auditExplanation = () => import('@/components/audit/auditExplanation')
const hasValidatedMessage = () => import('@/components/audit/hasValidatedMessage')
const validationCartouche = () => import('@/components/layout/validationCartouche')
const validationSectionButton = () => import('@/components/audit/validationSectionButton')

export default {
  name: 'ElementsContractuels',
  props: {
    id: {
      type: Number,
    }
  },
  components: {
    auditExplanation,
    auditItem,
    hasValidatedMessage,
    validationCartouche,
    validationSectionButton
  },
  mixins: [
    auditItemsMixins,
    auditSectionInformationMixins,
    userMixins,
    uxMixins,
    validationMixin
  ],
  data: function () {
    return {
      isGettingElementsContractuels: false,
      elementsContractuelsActiviteCedee: {
        audit: {
          has_cedant_validated: false,
          has_repreneur_validated: false,
          candidature: {
            description: '',
            offre_cession: {
              entreprise_cedee: {
                cedant: {
                  phone_number: '',
                  user: {
                    first_name: '',
                    last_name: '',
                    username: ''
                  }
                },
                name: '',
                siren: ''
              },
              price: '',
              title: '',

            },
            repreneur: {
              phone_number: '',
              user: {
                first_name: '',
                last_name: '',
                username: ''
              }
            },
            state: '3VALIDATED'
          },
        },
        cedant_comment: "",
        repreneur_comment: "",
        has_cedant_validated: false,
        has_repreneur_validated: false,
        items: [],

        etablissements: [],

      },

      newEtablissementSiret: '',
      newEtablissementAddress: '',

    }
  },
  computed: {
    cedantCanModified () {
      return this.userGroupContains(['CEDANT']) && !this.elementsContractuelsActiviteCedee.has_cedant_validated
    },

    token () {
      return this.$store.state.auth.token
    },
  },
  mounted: function() {
    this.scrollToTop()
    this.getElementsContractuels()
  },

  validations: {
    newEtablissementAddress: {
      required,
      minLength: minLength(2),
      maxLength: maxLength(240)
    },
    newEtablissementSiret: {
      required,
      integer,
      minLength: minLength(14),
      maxLength: maxLength(14)
    },
  },

  methods: {
    filterDocuments (type, uploader) {
      return this.elementsContractuelsActiviteCedee.items.filter(item => item.type === type && item.uploader === uploader)
    },
    addEtablissement () {
      const data = {
        'section': this.elementsContractuelsActiviteCedee.id,
        'siret': this.newEtablissementSiret,
        'address': this.newEtablissementAddress,
      }
      this.createAuditSectionInformation(data, 'etablissement')
    },
    informationAdded(data, sectionInformation) {
      if(sectionInformation === 'etablissement') {
        this.elementsContractuelsActiviteCedee.etablissements.push(data)
        this.newEtablissementSiret = ''
        this.newEtablissementAddress = ''
      }
    },
    informationDeleted(informationId, sectionInformation) {
      if(sectionInformation === 'etablissement') {
        this.elementsContractuelsActiviteCedee.etablissements = this.elementsContractuelsActiviteCedee.etablissements.filter(item => item.id !== informationId);
      }
    },
    documentModified () {
      this.getElementsContractuels()
    },
    getElementsContractuels () {
      this.isGettingElementsContractuels = true
      this.$store.commit('loadFromAPI')
      apiConnectedService.getAuditSection(this.token, 'elementsContractuelsActiviteCedee', this.id)
      .then((result) => {
        this.elementsContractuelsActiviteCedee = result.data
      })
      .catch((error) => {
        this.$store.commit('openGlobalErrorModal')
        throw new Error('ElementsContractuels getElementsContractuels API Error : ' + String(error))
      })
      .finally(() => {
        this.$store.commit('endLoadingFromAPI')
        this.isGettingElementsContractuels = false
      })
    },

    modifyElementsContractuels () {
      this.$store.commit('loadFromAPI')
      apiConnectedService.modifyAuditSection(this.token, 'elementsContractuelsActiviteCedee', this.id, this.elementsContractuelsActiviteCedee)
      .then((result) => {
        this.elementsContractuelsActiviteCedee = result.data
        this.$bvToast.hide('notification-toast')
        this.$bvToast.toast("Vos informations transmises dans l'audit ont bien été mises à jour", {
          id: 'notification-toast',
          title: 'Informations mises à jour',
          variant: 'success',
          solid: true
        })
      })
      .catch((error) => {
        this.$store.commit('openGlobalErrorModal')
        throw new Error('ElementsContractuels modifyElementsContractuels API Error : ' + String(error))
      })
      .finally(() => {
        this.$store.commit('endLoadingFromAPI')
      })
    },

    validateElementsContractuels (validationStatus) {
      const data = {}
      this.$store.commit('loadFromAPI')
      if (this.userGroupContains(['CEDANT'])) {
        data['has_cedant_validated'] = validationStatus
      }
      else if (this.userGroupContains(['REPRENEUR'])) {
        data['has_repreneur_validated'] = validationStatus
      }
      apiConnectedService.modifyAuditSection(this.token, "elementsContractuelsActiviteCedee",  this.id, data)
      .then((result) => {
        this.elementsContractuelsActiviteCedee = result.data
        if (validationStatus) {
          this.$bvToast.hide('notification-toast')
          this.$bvToast.toast("Félicitations, vous avez bien validé cette partie", {
            id: 'notification-toast',
            title: 'Section validée',
            variant: 'success',
            solid: true
          })
        }
            else {
          this.$bvToast.hide('notification-toast')
          this.$bvToast.toast("Vous avez bien invalidé cette partie, vous pouvez effectuer les modifications nécessaires, échanger avec l'autre partie puis à nouveau valider cette étape", {
            id: 'notification-toast',
            title: 'Etape invalidée',
            variant: 'danger',
            solid: true
          })
        }
        this.scrollToTop()
      })
      .catch((error) => {
        this.$store.commit('openGlobalErrorModal')
        throw new Error('ElementsContractuels validateElementsContractuels API Error : ' + String(error))
      })
      .finally(() => {
        this.$store.commit('endLoadingFromAPI')
      })

    },

  }
}
</script>
